:root {
  /* margins */
  --element-margin-right: 8px;
  --element-margin-bottom: 8px;

  /* padding */
  --button-padding-x: 32px;
  --small-button-padding-x: 22px;

  --group-padding-bottom: 8px;

  --section-padding-bottom: 24px;
  --icon-small-xy: 12px;

  --border-width: 2px;

  /* icons */
  --svg-processing: url('../icons/processing.svg');
  --svg-pending: url('../icons/pending.svg');
  --svg-arrow-right: url('../icons/arrow-right.svg');
}

@define-mixin icon-basics {
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: var(--button-padding-x);
  width: var(--icon-small-xy);
  height: var(--icon-small-xy);
}

@define-mixin icon-before {
  position: relative;
  padding-left: calc(var(--button-padding-x) + var(--icon-small-xy) + 10px);
  &:before {
    @mixin icon-basics;
  }
}

@define-mixin icon-after {
  position: relative;
  padding-right: calc(var(--button-padding-x) + var(--icon-small-xy) + 10px);
  z-index: 1;
  &:after {
    @mixin icon-basics;
    z-index: 2;
  }
}

@define-mixin icon-after {
  position: relative;
  padding-right: calc(var(--button-padding-x) + var(--icon-small-xy) + 10px);
  &:after {
    @mixin icon-basics;
  }
}

button {
  border: none;
}

label.btn,
a.btn,
.btn {
  /* letter-spacing: 1px; */
  background-color: #ddd;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 1.3;
  vertical-align: middle;
  font-weight: 400;
  font-size: var(--font-size-body);
  border-radius: 70px;
  height: 46px;
  padding: 2px 24px 0 24px;
  width: auto;
  min-width: 100px;
  outline: none;
  cursor: pointer;
  color: var(--color-grey-40);
  &.is-subtle {
    border: 1px solid var(--color-grey-60);
    background-color: transparent;
    color: var(--color-grey-60);
    &:hover {
      background-color: var(--color-grey-60);
      color: white;
    }
  }
  &:hover {
    background-color: var(--color-grey-40);
    color: white;
  }
  .icon {
    &:not(.is-tight) {
      margin-right: 8px;
    }
  }
  &.is-loading {
    cursor: not-allowed;
    .spin {
      @mixin spinner var(--color-primary);
    }
  }
  &.is-small {
    height: 36px;
    padding-right: var(--small-button-padding-x);
    padding-left: var(--small-button-padding-x);
    width: auto;
    min-width: 50px;
    &.is-loading {
      .spin {
        @mixin spinner var(--color-primary);
        width: 16px;
        height: 16px;
        margin-right: 0;
      }
    }
  }
  &.full-width {
    width: 100%;
  }
}

label.btn.is-primary,
a.btn.is-primary,
.btn.is-primary {
  background-color: var(--color-primary);
  border: var(--border-width) solid var(--color-primary);
  color: white;
  transition: var(--transition);
  &:hover:not(is-disabled):not(.is-disabled-opacity) {
    background-color: var(--color-primary-light);
    border: var(--border-width) solid var(--color-primary-light);
  }
  &.is-loading {
    .spin {
      @mixin spinner var(--color-primary);
    }
  }
  &:focus {
    background-color: var(--color-primary-light);
  }
}

.btn.is-danger {
  background-color: var(--color-mandy);
  border: var(--border-width) solid var(--color-mandy);
  color: white;
  transition: var(--transition);
  &:hover:not(is-disabled):not(.is-disabled-opacity) {
    background-color: var(--color-violet-red);
    border: var(--border-width) solid var(--color-violet-red);
  }
  &.is-loading {
    .spin {
      @mixin spinner var(--color-mandy);
    }
  }
  &:focus {
    background-color: var(--color-violet-red);
  }
}

label.btn.is-secondary,
a.btn.is-secondary,
.btn.is-secondary,
label.btn.is-outlined,
a.btn.is-outlined,
.btn.is-outlined {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-primary-contrast);
  transition: var(--transition);
  &:hover:not(.is-disabled):not(.is-disabled-opacity),
  &:focus:not(.is-disabled):not(.is-disabled-opacity) {
    background-color: #f0eaf9;
  }
}
label.btn.is-sequential,
a.btn.is-sequential,
.btn.is-sequential {
  position: relative;
  padding-right: calc(var(--button-padding-x) + var(--icon-small-xy));
  &:after {
    border: 2px solid white;
    border-top: none;
    border-right: none;
    content: '';
    position: absolute;
    right: var(--button-padding-x);
    top: 18px;
    height: 5px;
    width: 5px;
    transform: rotate(-135deg);
  }
  &:disabled {
    &:after {
      border: 2px solid #e6e4eb;
      border-top: none;
      border-right: none;
    }
  }
}

.btn.is-block {
  display: flex;
}

.btn.is-centered {
  margin-left: auto;
  margin-right: auto;
}

.btn.is-pending {
  @mixin icon-before;
  @mixin disabled;
  color: var(--color-warning);
  &:before {
    background-image: var(--svg-pending);
  }
}

.btn.is-processing {
  @mixin icon-before;
  @mixin disabled;
  color: var(--color-orange);
  &:before {
    background-image: var(--svg-processing);
  }
}

.btn.is-round {
  width: 46px;
  min-width: 46px;
  height: 46px;
  padding: 0;
}
.btn-group {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  .btn {
    vertical-align: top;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
.card-btn-wrapper {
  padding-top: 4px;
  padding-left: 3px;
  .card-btn {
    letter-spacing: 0.6px;
    font-weight: 600;
    border: 1px solid var(--color-primary);
    position: relative;
    padding-top: 76px;
    padding-bottom: 24px;
    .icon {
      position: absolute;
      top: 30px;
    }
    span {
      margin-top: 0px;
    }
    display: flex;
    /* width: 194px; */
    height: auto;
    min-height: 123px;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition-duration: var(--speed);
    transition-property: background-color, opacity, transform;
    transition-timing-function: ease-out;
    .is-body {
      color: var(--color-primary);
    }
    &:hover:not(.is-disabled):not(.is-disabled-opacity) {
      border: 1px solid var(--color-primary);
      box-shadow: 3px 3px var(--color-primary);
      background-color: white;
    }
    margin-right: 8px;
  }
  &:hover:not(.is-disabled):not(.is-disabled-opacity) {
    padding-top: 0;
    padding-left: 0;
  }
}

.btn-dashboard {
  margin-top: 20px;
}

.btn.is-link-button {
  color: var(--color-grey-40);
  border: none;
  padding: 0px;
  margin: 2px;
  background-color: transparent;
  text-align: left;
  /* letter-spacing: 1px; */
  line-height: 1.3;
  vertical-align: middle;
  font-weight: 400;
  word-wrap: none;
  width: auto;
  height: auto;
  min-width: 10px;
  outline: none;
  cursor: pointer;
  &.no-min-width {
    min-width: 0px !important;
  }
  &.color-primary {
    color: var(--color-primary);
    border: none;
    background-color: transparent;
    &:hover,
    &:focus {
      color: var(--color-primary-light);
      background-color: transparent;
    }
  }
  &.color-danger {
    color: var(--color-violet-red);
    &:hover,
    &:focus {
      color: var(--color-mandy);
      background-color: transparent;
    }
  }
  &:disabled {
    color: var(--color-grey-80) !important;
    cursor: not-allowed !important;
  }
  &:hover,
  &:focus {
    color: var(--color-grey-40-transparent);
    background-color: transparent;
  }
}

/* Buttons for Sharing feature - old/not used */

/*
.btn-record-share {
  padding: 14px 20px;
  border-radius: 40px;
  height: auto;
  width: 100%;
  margin: 5px 0 5px 0;
}

.is-secondary.btn-record-share {
  border: none;
  &:hover,
  &:focus {
    background: var(--color-grey-60);
  }
}

.btn.is-secondary:hover:not(.is-disabled):not(.is-disabled-opacity).btn-record-share {
  &:hover,
  &:focus {
    color: var(--color-primary);
    background: var(--color-grey-60);
  }
}

button.btn.is-secondary.btn-record-share--revoke {
  padding: 0 10px;
  height: 30px;
  margin-top: 6px;
  border: 1px solid var(--color-error);
  color: var(--color-error);
  transition: all 0.2s;
  font-size: var(--font-size-xxx-small);
  &:hover,
  &:focus {
    background: var(--color-error);
    color: white;
  }
}

.btn.is-secondary:hover:not(.is-disabled):not(.is-disabled-opacity).btn-record-share--revoke {
  &:hover,
  &:focus {
    background: var(--color-error);
    color: white;
  }
}

*/

/* Buttons for Sharing feature */
.btn-record-share {
  background: transparent;
  border: 1px solid;
  text-transform: none;
  border-radius: 40px;
  letter-spacing: 0px;
  margin: 0px 10px;
}

.btn-record-share-secondary {
  background: transparent;
  border: none;
  text-transform: none;
  text-decoration: underline;
  padding: 0px;
  letter-spacing: 0px;
  height: auto;
  margin: 0px 10px;
}

.btn-record-share-secondary:hover {
  color: var(--color-grey-60);
  background: none;
}

/* RESPONSIVE */

@mixin until $mobile {
  a.btn,
  button.btn {
    width: 100%;
  }
}

.verification button.btn.is-primary {
  margin-top: 30px;
}

/* MODIFIER */
@define-mixin disabled {
  cursor: not-allowed;
  background-color: transparent;
  border: 2px solid #e1e2e1;
  color: #e6e4eb;
  &:after {
    border-color: #e1e2e1;
  }
}

.is-disabled {
  @mixin disabled;
}

.is-disabled-opacity {
  cursor: not-allowed;
  background-color: var(--color-grey-80) !important;
  border: 2px solid var(--color-grey-80) !important;
  &:hover {
    cursor: not-allowed !important;
  }
}

.auth-button--maple {
  border: 3px solid var(--color-purple-heart);
  background-color: white;
}


.btn.icon-only {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  padding: 0;
}