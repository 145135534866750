@import './variables.css';

:root {
  --tracked {
    @mixin uppercase;
    letter-spacing: 1px;
  }
  --padding-primary: 32px;
}

.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: #3340374f; /* grey-100 w/ opacity */
  @mixin flex-column;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-modal);
  /* overriding input behaviour on desktop */
  .columns.is-desktop {
    display: block;
  }
  &__content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    box-shadow: 0px 0px 16px rgba(42, 10, 90, 0.12);
    border-radius: 20px;
    width: 50%; /* Could be more or less, depending on screen size */
    @mixin mobile {
      width: 90%;
    }
  }
  &__close-icon {
    align-self: flex-end;
    background-color: transparent;
    border: none;
    outline: none;
    margin: -20px -10px 8px -10px;
    min-width: fit-content;
    padding: 10px;
    height: fit-content;
    &:hover {
      background: none;
    }
  }
  &__title {
    font-size: 2.8rem;
    text-align: center;
    margin: 0;
  }
  &__sub-title,
  .type-paragraph {
    font-size: 1.6rem;
    line-height: 1.6;
    font-weight: 100;
    /* text-align: center; */
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }   
  &__sub-title {
    margin: 6px 0 35px 0;
  }
  .type-paragraph {
    margin: 26px 0 8px 0;
  }
  .override-paragraph-styles {
    .type-paragraph {
      margin: 0px;
    }
  }
}

.modal {
  .dropdown__container {
    margin-bottom: 37px;
    select#expiry_date {
      height: 62px;
    }
  }
}

.modal__shared--success {
  background: var(--color-primary);
  margin-top: 50%;
  .modal__title {
    color: white;
    font-size: 2.6rem;
  }
  .modal__sub-title {
    font-size: 57px;
  }
}

.modal__shared-history {
  &__list {
    margin: 0;
    padding: 0 30px;
    list-style: none;
  }
  &__list__item {
    @mixin flex-row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 55px;
  }
  &__container {
    min-width: 125px;
    p {
      margin: 0;
      padding: 0;
      text-align: left;
    }
    &:nth-of-type(2) p {
      text-align: center;
      font-style: italic;
    }
  }
}

.modal-grid {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 2rem 0;
}
@media only screen and (max-width: 1350px) {
  .modal-grid {
    grid-template-columns: 1fr;
  }
}
