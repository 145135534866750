.is-faded {
  opacity: 0.5;
}

.is-error,
.is-danger {
  color: var(--color-error);
  a {
    :hover {
      color: var(--color-mandy);
    }
  }
}

.is-white {
  color: white;
}

.is-success {
  color: var(--color-success);
}

.is-pending {
  color: var(--color-orange);
}

.is-subtitle {
  padding: 24px 0 28px 0;
  max-width: 650px;
}

.is-centered,
.is-center {
  text-align: center;
  margin: 0 auto;
}

.is-left,
.is-left-aligned {
  text-align: left;
  margin: 0;
}

.is-right {
  text-align: right;
  right: 0;
}

.is-center {
  text-align: center;
}

.is-subtext {
  color: grey;
  letter-spacing: 0.7;
  font-size: var(--font-size-x-small);
}

.is-bold {
  font-weight: 600;
}

.is-italic {
  font-style: italic;
  font-size: 14px;
}

.is-underlined {
  text-decoration: underline;
}

.is-dotted-underline {
  background: url('../icons/dash.svg') bottom left repeat-x;
}

.is-extra-bold,
a.is-extra-bold {
  font-weight: 600;
}

.is-hidden {
  display: none;
}

/* decoy modifier  - to trick autofill to fill a form outside page */
.is-absolutely-hidden {
  position: absolute;
  top: -999px;
  left: -999px;
}

/* background colour modifiers */
.hover--is-bg {
  &-purple:hover {
    @mixin color-fill var(--color-primary);
  }
  &-ship-cove:hover {
    @mixin color-fill var(--color-ship-cove);
  }
  &-persian-rose:hover {
    @mixin color-fill var(--color-persian-rose);
  }
  &-disco-blue:hover {
    @mixin color-fill var(--color-disco-blue);
  }
  &-lavender:hover {
    @mixin color-fill var(--color-lavender);
  }
  &-forest-green:hover {
    @mixin color-fill var(--color-forest-green);
  }
  &-orange:hover {
    @mixin color-fill var(--color-orange);
  }
  &-neon-blue:hover {
    @mixin color-fill var(--color-neon-blue);
  }
  &-neon-purple:hover {
    @mixin color-fill var(--color-neon-purple);
  }
  &-mandy:hover {
    @mixin color-fill var(--color-mandy);
  }
  &-turquoise-blue:hover {
    @mixin color-fill var(--color-turquoise-blue);
  }
  &-rose:hover {
    @mixin color-fill var(--color-rose);
  }
}

.is-bg {
  &-yellow {
    @mixin color-fill var(--color-yellow);
  }
  &-purple {
    @mixin color-fill var(--color-primary);
  }
  &-ship-cove {
    @mixin color-fill var(--color-ship-cove);
  }
  &-persian-rose {
    @mixin color-fill var(--color-persian-rose);
  }
  &-disco-blue {
    @mixin color-fill var(--color-disco-blue);
  }
  &-lavender {
    @mixin color-fill var(--color-lavender);
  }
  &-forest-green {
    @mixin color-fill var(--color-forest-green);
  }
  &-orange {
    @mixin color-fill var(--color-orange);
  }
  &-neon-blue {
    @mixin color-fill var(--color-neon-blue);
  }
  &-neon-purple {
    @mixin color-fill var(--color-neon-purple);
  }
  &-mandy {
    @mixin color-fill var(--color-mandy);
  }
  &-turquoise-blue {
    @mixin color-fill var(--color-turquoise-blue);
  }
  &-rose {
    @mixin color-fill var(--color-rose);
  }
}

.is-outline {
  &-yellow {
    color: var(--color-warning);
    border: 1px solid var(--color-warning);
  }
  &-purple {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }
  &-ship-cove {
    color: var(--color-ship-cove);
    border: 1px solid var(--color-ship-cove);
  }
  &-persian-rose {
    color: var(--color-persian-rose);
    border: 1px solid var(--color-persian-rose);
  }
  &-disco-blue {
    color: var(--color-disco-blue);
    border: 1px solid var(--color-disco-blue);
  }
  &-lavender {
    color: var(--color-lavender);
    border: 1px solid var(--color-lavender);
  }
  &-forest-green {
    color: var(--color-forest-green);
    border: 1px solid var(--color-forest-green);
  }
  &-orange {
    color: var(--color-orange);
    border: 1px solid var(--color-orange);
  }
  &-neon-blue {
    color: var(--color-neon-blue);
    border: 1px solid var(--color-neon-blue);
  }
  &-neon-purple {
    color: var(--color-neon-purple);
    border: 1px solid var(--color-neon-purple);
  }
  &-mandy {
    color: var(--color-mandy);
    border: 1px solid var(--color-mandy);
  }
  &-turquoise-blue {
    color: var(--color-turquoise-blue);
    border: 1px solid var(--color-turquoise-blue);
  }
  &-rose {
    color: var(--color-rose);
    border: 1px solid var(--color-rose);
  }
}

.is-uppercase {
  text-transform: uppercase;
}

.is-light {
  color: white;
  &--purple {
    color: var(--color-lavender);
  }
}

.is-purple {
  color: var(--color-purple-heart);
}
.is-grey {
  color: var(--color-grey-80);
}

.is-red {
  color: var(--color-violet-red);
}

.is-grey-40 {
  color: var(--color-grey-40);
}

.is-grey-30 {
  color: var(--color-grey-30);
}

.is-grey-60 {
  color: var(--color-grey-60);
}

.is-ship-cove {
  color: var(--color-ship-cove);
}

.has-icon {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 10px;
    min-width: 16px;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.is-chevron {
  &--down::after,
  &--up::after {
    position: absolute;
    content: '';
    top: 22px;
    right: 22px;
    height: 10px;
    width: 10px;
    border: 2px solid var(--color-purple-heart);
    border-top: none;
    border-right: none;
  }
  &--up::after {
    transform: rotate(135deg);
  }
  &--down::after {
    transform: rotate(-45deg);
  }
}

.is-clickable {
  cursor: pointer;
  outline: none;
}

.no-border {
  border: none !important;
}

.pl2 {
  padding-left: 2px;
}

.pr2 {
  padding-right: 2px;
}

.pa2 {
  padding: 2px;
}

.ml12 {
  margin-left: 12px;
}

.mt12 {
  margin-top: 12px;
}

.mr12 {
  margin-right: 12px;
}

.mb10 {
  margin-bottom: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt10 {
  margin-top: 10px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.full-width {
  width: 100% !important;
}

.font-weight {
  &-300 {
    font-weight: 300;
  }
  &-350 {
    font-weight: 350;
  }
  &-400 {
    font-weight: 400;
  }
  &-500 {
    font-weight: 500;
  }
  &-600 {
    font-weight: 600;
  }
}

.gap {
  &-5 {
    gap: 5px
  }
  &-10 {
    gap: 10px
  }
  &-15 {
    gap: 15px
  }
  &-20 {
    gap: 20px
  }
  &-25 {
    gap: 25px
  }
  &-30 {
    gap: 30px
  }
  &-35 {
    gap: 35px
  }
}
