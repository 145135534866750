.loader {
  &.is-loading {
    @mixin spinner var(--color-primary);
  }
  margin: 50px auto;
}

.spin {
  @mixin spinner var(--color-primary);
}
