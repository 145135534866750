html {
  font-size: 16px;
  font-size: 62.5%;
}

html, body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: var(--font-default);
  font-weight: 300;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.6rem;
}

* {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

strong {
  font-weight: 400;
}

svg {
  display: block;
}

a {
  cursor: pointer;
}

.MuiSlider-root:focus {
  color: #2e8b57;
}